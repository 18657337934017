import { Injectable } from '@angular/core';

declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

    show(type, message){
        //let type = ['','info','success','warning','danger'];
        let icons = {'info':'pe-7s-info','success':'pe-7s-check','warning':'pe-7s-look','danger':'pe-7s-close'};

    	$.notify({
        	icon: icons[type],
        	message: message

        },{
            type: type,
            timer: 4000,
            placement: {
                from: 'top',
                align: 'right'
            }
        });
	}
}
