import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../services/user.service';
import { UserType } from '../interfaces/user';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private router:Router, private user: UserService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    let user:UserType = this.user.getUser();

    if(!user){
      this.router.navigate(['/users/login']);
    // }else if(user?.status==1 && state?.url!='/setup/pay' && state?.url!='/setup/thanks' && state?.url!='/setup/stp'){
    //   this.router.navigate(['/setup/pay']);
    }else if(user?.status==1 && state?.url!='/users/pay' && state?.url!='/setup/thanks' && state?.url!='/setup/stp'){
      this.router.navigate(['/users/pay']);
    }else if(user?.status==2 && user?.setup==1 && state?.url!='/users/setup'){
      this.router.navigate(['/users/setup']);
    }else{
      return true;
    }
    // if(!this.user.getToken()){
    //   this.router.navigate(['/users/login']);
    // }else if(user?.status==1 && state?.url!='/setup/pay' && state?.url!='/setup/thanks' && state?.url!='/setup/stp'){
    //   this.router.navigate(['/setup/pay']);
    // }else if(user?.status==2 && user?.setup==1 && state?.url!='/setup/wizard'){
    //   this.router.navigate(['/setup/wizard']);
    // }else{
    //   return true;
    // }

  	// if (!this.user.getToken()) {
  	// 	this.router.navigate(['/users/login']);
  	// } else {
   //  	return true;
   //  }
  }

}
