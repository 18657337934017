import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgxUploaderModule } from 'ngx-uploader';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ColorPickerModule } from 'ngx-color-picker';

import { ModalModule } from './components/modal';

import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ListingComponent } from './components/listing/listing.component';
import { AlertComponent } from './components/alert/alert.component';

import { ParseTitlePipe } from './pipes/parse-title.pipe';
import { UploaderComponent } from './components/uploader/uploader.component';

@NgModule({
  declarations: [
	 	FooterComponent,
		LoaderComponent,
		NavbarComponent,
		SidebarComponent,
		ListingComponent,
		AlertComponent,
		ParseTitlePipe,
    UploaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ModalModule,
    NgxUploaderModule,
    ImageCropperModule,
    ColorPickerModule
  ],
  exports: [
  	FooterComponent,
  	LoaderComponent,
  	NavbarComponent,
  	SidebarComponent,
    AlertComponent,
    UploaderComponent,
    ParseTitlePipe,
    ModalModule,
  ],
})

export class SharedModule { }
