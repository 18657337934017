import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html'
})
export class AdminComponent implements OnInit {

  constructor(private _userSer: UserService, private _router: Router) {

  }

  ngOnInit() {
  	let user = this._userSer.getUser();
  	//this._router.url
  	if(user?.status==1){
		// this._router.navigate(['/setup/pay']);
  	}
  }

}
