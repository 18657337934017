import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

import { SharedService } from './shared.service';
import { DefaultsType } from '../interfaces/defaults';

import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})

export class SiteService extends SharedService {

  // public dashboardSub = new BehaviorSubject<any>({});  
  // public defaultSub   = new BehaviorSubject<any>({});  
  public currencySub  = new BehaviorSubject<any>({});  
  public sliderSub    = new BehaviorSubject<any>({});  
  public publishSub   = new BehaviorSubject<any>({});

  // getDefaults(){
  //   let defaults = this.getDefaultsFromCache();
  //   if(_.isEmpty(defaults)){
  //     this.getDefaultsFromService().subscribe(()=>{
  //       defaults = this.getDefaultsFromCache();
  //       this.defaultSub.next(defaults);
  //     });
  //   }else{
  //     this.defaultSub.next(defaults);
  //   }
  // }

  // getDefaultsFromCache():DefaultsType{
  //   return this.cache.get('defaults');
  // }

  // getDefaultsFromService():Observable<DefaultsType>{
  //   let token = this.getToken();

  //   return Observable.create(observer => {
  //     this.http.get<any>(this.apiUrl + "site/defaults?token="+token, this.httpOptions).subscribe(r=>{
  //       this.cache.set('defaults',r);
  //       observer.next(r);
  //       observer.complete();
  //     }, (error) => {
  //       this.handleError(error);
  //       // observer.error(error.error);
  //     });
  //   });
  // }

  // getCurrencies(){
  //   let crs = this.getCurrenciesFromCache();
  //   if(_.isEmpty(crs)){
  //     this.getCurrenciesFromService().subscribe(()=>{
  //       crs = this.getCurrenciesFromCache();
  //       this.currencySub.next(crs);
  //     });
  //   }else{
  //     this.currencySub.next(crs);
  //   }
  // }

  // getCurrenciesFromCache():DefaultsType{
  //   return this.cache.get('currencies');
  // }

  // getCurrenciesFromService():Observable<DefaultsType>{
  //   let token = this.getToken();

  //   return Observable.create(observer => {
  //     this.http.get<any>(this.apiUrl + "site/currencies?token="+token, this.httpOptions).subscribe(r=>{
  //       this.cache.set('currencies',r);
  //       observer.next(r);
  //       observer.complete();
  //     }, (error) => {
  //       this.handleError(error);
  //       // observer.error(error.error);
  //     });
  //   });
  // }

  // getDashboard(){
  //   let token = this.getToken();

  //   return Observable.create(observer => {
  //     this.http.get<any>(this.apiUrl + "site/dashboard?token="+token, this.httpOptions).subscribe(r=>{
  //       observer.next(r);
  //       observer.complete();
  //     }, (error) => {
  //       this.handleError(error);
  //       // observer.error(error.error);
  //     });
  //   });
  // }

  // updateDefaults(defaults):Observable<any>{
  //   let token = this.getToken();
  //   let header = this.getTokenHeader(token);
  //   return Observable.create(observer => {
  //     this.http.post<any>(this.apiUrl + "site/defaults",defaults, header).subscribe(r=>{
  //         this.cache.set('defaults',r);
  //         this.defaultSub.next(r);
  //         this.publishSub.next(true);
  //         observer.next("updated");
  //         observer.complete();
  //     }, (error) => {
  //       this.handleError(error);
  //       // observer.error(error.error);
  //     });
  //   });
  // }  

  exportDefaults():Observable<any>{
    let token = this.getToken();

    return Observable.create(observer => {
      this.http.get<any>(this.apiUrl + "site/export_defaults?token="+token, this.httpOptions).subscribe(r=>{
        this.cache.set('defaults',{});
        observer.next();
        observer.complete();
      }, (error) => {
        this.handleError(error);
        // observer.error(error.error);
      });
    });
  }

// @TODO: Commenting defaultSub for now, 
  
  uploadAsset(xData){
    let token = this.getToken();

    return Observable.create(observer => {
      this.http.post<any>(this.apiUrl + "site/upload_asset?token="+token,xData,this.httpOptions).subscribe(res=>{
        this.cache.set('defaults',res.data);
        this.publishSub.next(true);
        // this.defaultSub.next(res.data);
        observer.next(res.msg);
        observer.complete();
      }, (error) => {
        this.handleError(error);
        // observer.error(error.error);
      });
    });    
  }

  loadSlider(id){
    let sliders = this.getSliderFromCache();
    if(_.isEmpty(sliders)){
      console.log('Error!');
    }else{
      return sliders.find(sliders=>sliders.id==id);
    }
  }

  // getSlider(){
  //   let slider = this.getSliderFromCache();
  //   if(_.isEmpty(slider)){
  //     this.getSliderFromService().subscribe(()=>{
  //       slider = this.getSliderFromCache();
  //       this.sliderSub.next(slider);
  //     });
  //   }else{
  //     this.sliderSub.next(slider);
  //   }
  // }

  getSliderFromCache():any{
    return this.cache.get('slider');
  }

  // getSliderFromService():Observable<any>{
  //   let token = this.getToken();

  //   return Observable.create(observer => {
  //     this.http.get<any>(this.apiUrl + "slider?token="+token, this.httpOptions).subscribe(r=>{
  //       this.cache.set('slider',r);
  //       observer.next(r);
  //       observer.complete();
  //     }, (error) => {
  //       this.handleError(error);
  //     });
  //   });
  // }

  createSlider(xData){
    let token = this.getToken();

    return Observable.create(observer => {
      this.http.post<any>(this.apiUrl + "slider?token="+token,xData,this.httpOptions).subscribe(res=>{
        this.cache.set('slider',res.data);
        this.sliderSub.next(res.data);
        observer.next(res.msg);
        observer.complete();
      }, (error) => {
        this.handleError(error);
        // observer.error(error.error);
      });
    });    
  }  

  deleteSlider(id){
    let token = this.getToken();
    return Observable.create(observer => {
      this.http.delete<any>(this.apiUrl + "slider?token="+token+"&id="+id,this.httpOptions).subscribe(res=>{
        let s = res.data;
        this.cache.del('slider');
        this.cache.set('slider',s);
        this.sliderSub.next(s);  
        observer.next(res.msg);
        observer.complete();
      }, (error) => {
        this.handleError(error);
        // observer.error(error.error);
      });
    });    
  }

  setStatus(id, status){
    let token = this.getToken();

    return Observable.create(observer => {
      this.http.put<any>(this.apiUrl + "slider?token="+token+"&id="+id+"&status="+status,this.httpOptions).subscribe(res=>{
        let s = res.data;
        this.cache.del('slider');
        this.cache.set('slider',s);
        this.sliderSub.next(s);
        observer.next(res.msg);
        observer.complete();
      }, (error) => {
        this.handleError(error);
        // observer.error(error.error);
      });
    });    
  }

  checkPromo(promo){
    let token = this.getToken();

    return Observable.create(observer => {
      this.http.get(this.apiUrl + "site/check_promo?token="+token+"&code="+promo,this.httpOptions).subscribe(res=>{
        observer.next(res);
        observer.complete();
      }, (error) => {
        this.handleError(error);
        // observer.error(error?.error);
      });
    });
  }

  getFonts(){
    let token = this.getToken();
    return Observable.create(observer => {
      this.http.get(this.apiUrl + "site/fonts?token="+token, this.httpOptions).subscribe((res:any)=>{
        observer.next(res);
        observer.complete();
      }, (error) => {
        this.handleError(error);
        // observer.error(error?.error);
      });
    });
  }
  // getDefaults(){
  // 	let defaults = this.getDefaultsFromCache();
  // 	if(_.isEmpty(defaults)){
  // 		this.getDefaultsFromService().subscribe(()=>{
  // 			defaults = this.getDefaultsFromCache();
  // 			this.defaultSub.next(defaults);
  // 		});
  // 	}else{
  // 		this.defaultSub.next(defaults);
  // 	}
  // }

  // getDefaultsFromCache():DefaultsType{
  // 	return this.cache.get('defaults');
  // }

  // delDefaultsFromCache(){
  //   this.cache.del('defaults');
  // }

  // getDefaultsFromService():Observable<DefaultsType>{
  // 	let token = this.getToken();

  // 	return Observable.create(observer => {
  // 		this.http.get<any>(this.apiUrl + "site/defaults?token="+token,this.httpOptions).subscribe(r=>{
  // 		    this.cache.set('defaults',r.data);
  // 		    observer.next(r.data);
  // 		    observer.complete();
  // 		}, (error) => {
  // 	    	observer.error(error.error);
  //   	});
  // 	});
  // }
}
