import { NgModule, InjectionToken } from '@angular/core';
import { environment } from '../environments/environment';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export class AppConfig {
  api: string;
  cdn: string;
  app: string;
  stripe: string;
  colors: Array<string>;
  languages: Array<string>;
  default_language: string;
  ipinfo: string;
}

export const APP_DI_CONFIG: AppConfig = {
  api: environment.api,
  cdn: environment.cdn,
  app: environment.app,
  stripe: environment.stripe,
  ipinfo: environment.ipinfo,
  colors: environment.colors,
  languages: environment.languages,
  default_language: environment.default_language
};

@NgModule({
  providers: [{
    provide: APP_CONFIG,
    useValue: APP_DI_CONFIG
  }]
})
export class AppConfigModule { }
