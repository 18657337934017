export const environment = {
  production: false,
  // api: 'https://dev-api.oxq.io/v2/',
  api: 'http://localhost/v2/public/v2/',
  cdn: 'https://static.oxq.io/',
  app: 'https://cp.oxq.io/',
  colors: ['red', 'pink', 'orange', 'yellow', 'green', 'blue'],
  languages: ['ur','en'],
  default_language: 'en',
  // stripe: 'pk_test_51HTQuzL95D13ISh6D18kzTnwzS1VIQZaDuTgla7YUcVwsin4kfTUw67PMoEFid9Zu0vBOVLsBtEc56lGYd4vqVaw0033jRuboH',
  stripe: 'pk_test_51POdaG07ZFAMYmdg0RrbvGvis9jMpOkMrhU1YH2ovVoSOgcVdXvCfDyXrFYLZ6XUV8rF8JuYQafZvcPyMFPwsLIo00DchtqPPJ',
  ipinfo: '36356767f0cb5a',
  recaptchaSiteKey: '6Lc3YOUnAAAAAN8TKtz0BQlutVKGYeX6HYdrzE4B'
};
