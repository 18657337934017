import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
// import { ROUTES } from '../sidebar/sidebar.component';

import { UserService } from '../../services/user.service';
import { SiteService } from '../../services/site.service';

import * as _ from 'underscore';

var misc:any ={
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
}
declare var $: any;

@Component({
  selector: 'ox-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {

  constructor(location:Location, protected element : ElementRef, protected router:Router, protected userSer: UserService, protected siteSer: SiteService, protected renderer: Renderer2) {
  	this.nativeElement = element.nativeElement;
  	this.sidebarVisible = false;  	
  }

  private listTitles: any[];
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  location: Location;

  shortMenus = [
    {
      name: 'Dashboard',
      link: '/dashboard'
    }, {
      name: 'List Products',
      link: '/products/list'
    }, {
      name: 'List Categories',
      link: '/categories/list'
    }, {
      name: 'List All Themes',
      link: '/themes/all'
    }, {
      name: 'List Orders',
      link: '/orders/all'
    }, 
  ];

  longMenus = [{
    name: 'Dashboard',
    link: '/dashboard'
  }, {
    name: 'List Products',
    link: '/products/list'
  }, {
    name: 'Add New Product',
    link: 'products/new'
  }, {
    name: 'List Categories',
    link: '/categories/list'
  }, {
    name: 'Add New Categories',
    link: '/category/new'
  }, {
    name: 'List Brands',
    link: '/brands/list'
  }, {
    name: 'Add New Brands',
    link: '/brand/neww'
  }, {
    name: 'List Orders',
    link: '/orders/all'
  }, {
    name: 'Orders in Processing',
    link: '/orders/processing'
  }, {
    name: 'Delivered Orders',
    link: '/orders/delivered'
  }, {
    name: 'Cancelled Orders',
    link: '/orders/cancelled'
  }, {
    name: 'List All Themes',
    link: '/themes/all'
  }, {
    name: 'Theme Sections',
    link: '/themes/sections'
  }, {
    name: 'Theme Settings',
    link: '/themes/settings'
  }, {
    name: 'Update Store Color',
    link: '/themes/settings'
  }, {
    name: 'Update Store Font',
    link: '/themes/settings'
  }, {
    name: 'Update Store Direction (Left to Right)',
    link: '/themes/settings'
  }, {
    name: 'Update Logo',
    link: '/customize/logo'
  }, {
    name: 'List Slider',
    link: '/customize/slider'
  }, {
    name: 'Add New Slider',
    link: '/customize/add-slider'
  }, {
    name: 'Inner Page Header',
    link: '/customize/header'
  }, {
    name: 'Menu/Navigation',
    link: '/customize/menu'
  }, {
    name: 'Update Favicon',
    link: '/customize/icons'
  }, {
    name: 'Update Footer',
    link: '/customize/footer'
  }, {
    name: 'Add Custom Code',
    link: '/customize/code'
  }, {
    name: 'Add Custom CSS',
    link: '/customize/code'
  }, {
    name: 'Add Custom Javascript (JS)',
    link: '/customize/code'
  }, {
    name: 'Update General Settings',
    link: '/settings/all'
  }, {
    name: 'Update S.E.O. (SEO) Settings',
    link: '/settings/all'
  }, {
    name: 'Update Domain',
    link: '/settings/domain'
  }, {
    name: 'Install SSL',
    link: '/settings/domain'
  }, {
    name: 'Add Social Media Links (facebook, twitter etc)',
    link: '/settings/social'
  }, {
    name: 'Update Currency',
    link: '/settings/cart'
  }, {
    name: 'Update Shipping Detail',
    link: '/settings/cart'
  }, {
    name: 'Update Cart Settings',
    link: '/settings/cart'
  }, {
    name: 'Update Payment Settings',
    link: '/settings/payment'
  }, {
    name: 'Update Email Settings',
    link: '/settings/email'
  }, {
    name: 'SMTP Settings',
    link: '/settings/email'
  }, {
    name: 'Integrate Google Analytics',
    link: '/settings/analytics'
  }, {
    name: 'Change/Update Password',
    link: '/settings/password'
  }, {
    name: 'Partners',
    link: '/connect/all'
  }, {
    name: 'Eveline/Remz.tech/Printify',
    link: '/connect/all'
  }];

  selectedMenu = "";
  showMenu     = false;
  showPublish  = false;
  largeSideBar = true;

  selectedMenuObj:any;
  menus:any;
  user;

  ngOnInit() {
  
  this.user  = this.userSer.getUser();
  this.menus = this.shortMenus;    
	// this.listTitles = ROUTES.filter(listTitle => listTitle);

  this.siteSer.publishSub.subscribe(r=>{
    if(r==true){
      this.showPublish = true;
    }else{
      this.showPublish = false;
    }
  });

    // var navbar : HTMLElement = this.element.nativeElement;
    // this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    // if($('body').hasClass('sidebar-mini')){
    //     misc.sidebar_mini_active = true;
    // }
    // $('#minimizeSidebar').click(function(){
    //     var $btn = $(this);

    //     if(misc.sidebar_mini_active == true){
    //         $('body').removeClass('sidebar-mini');
    //         misc.sidebar_mini_active = false;

    //     }else{
    //         setTimeout(function(){
    //             $('body').addClass('sidebar-mini');

    //             misc.sidebar_mini_active = true;
    //         },300);
    //     }

    //     // we simulate the window Resize so the charts will get updated in realtime.
    //     var simulateWindowResize = setInterval(function(){
    //         window.dispatchEvent(new Event('resize'));
    //     },180);

    //     // we stop the simulation of Window Resize after the animations are completed
    //     setTimeout(function(){
    //         clearInterval(simulateWindowResize);
    //     },1000);
    // });  	
  }

  onChange(){
    if(this.selectedMenu.length>0){
      this.menus = this.longMenus
    }else{
      this.menus = this.shortMenus;
    }

    this.selectedMenuObj = this.menus.find((c)=> c.name==this.selectedMenu);

    if(this.selectedMenuObj?.link){
      this.router.navigate([this.selectedMenuObj?.link]);
    }
  }

  toggleMenu(){
    this.showMenu = !this.showMenu;
  }

  handleSidebar(){
    // console.log(this.largeSideBar);
    if(this.largeSideBar){
      this.renderer.addClass(document.body, 'g-sidenav-hidden');
      this.largeSideBar = false;
    }else{
      this.renderer.removeClass(document.body, 'g-sidenav-hidden');
      this.largeSideBar = true;
    }
  }

  isMobileMenu(){
    if($(window).width() < 991){
        return false;
    }
    return true;
  }

  sidebarOpen(){
    var toggleButton = this.toggleButton;
    var body = document.getElementsByTagName('body')[0];
    setTimeout(function(){
        toggleButton.classList.add('toggled');
    },500);
    body.classList.add('nav-open');
    this.sidebarVisible = true;
  }

  sidebarClose(){
    var body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  }

  sidebarToggle(){
    if(this.sidebarVisible == false){
        this.sidebarOpen();
    } else {
        this.sidebarClose();
    }
  }

  logout(){
    this.userSer.logout().subscribe(r=>{
      this.router.navigate(['/users/login']);
    });
  }

  publish(){
    this.siteSer.exportDefaults().subscribe(r=>{
      this.showPublish = false;
    });
  }
}
