import { NgModule } from '@angular/core';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
     loader: {
       provide: TranslateLoader,
       useFactory: httpTranslateLoader,
       deps: [HttpClient]
     }
    }),    
  ],
  exports: [ TranslateModule ]
})

export class CommonXModule { }
