<nav class="navbar navbar-main navbar-expand-lg position-sticky mt-3 top-1 px-0 mx-3 blur shadow-none border-radius-l z-index-sticky" id="navbarBlur" data-scroll="true">
    <div class="container-fluid py-1 px-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5">
          <li class="breadcrumb-item text-sm">
            <a class="opacity-3 text-dark" href="javascript:;">
              <span class="material-icons text-secondary position-relative text-lg">cottage</span>
            </a>
          </li>
          <li class="breadcrumb-item text-sm"><a class="opacity-5 text-dark" href="javascript:;">Pages</a></li>
          <li class="breadcrumb-item text-sm text-dark active" aria-current="page">Analytics</li>
        </ol>
        <h6 class="font-weight-bolder mb-0">Analytics</h6>
      </nav>

      <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
        <div class="ms-md-auto pe-md-3 d-flex align-items-center">
          <div class="input-group input-group-outline">
            <label class="form-label">Search here</label>
            <input list="id-menu" name="id-menu" [(ngModel)]="selectedMenu" value="{{selectedMenuObj?.name}}" (ngModelChange)="onChange()" class="form-control" autocomplete="off">
            <datalist id="id-menu">
              <option *ngFor="let menu of menus; let i = index" [value]="menu?.name">{{menu?.name}}</option>
            </datalist>            
          </div>
        </div>
        <ul class="navbar-nav  justify-content-end">
          <li class="nav-item">
            <a href="../../pages/authentication/signin/illustration.html" class="nav-link text-body p-0 position-relative" target="_blank">
              <i class="material-icons me-sm-1">
            account_circle
          </i>
            </a>
          </li>
          <!-- <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a href="javascript:;" class="nav-link text-body p-0" id="iconNavbarSidenav">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li> -->
          <li class="nav-item px-3">
            <a class="nav-link text-body p-0" href="javascript:void(0);" (click)="handleSidebar();">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
          </li>
          <li class="nav-item dropdown pe-2">
            <a href="javascript:;" class="nav-link text-body p-0 position-relative" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="material-icons cursor-pointer">
            notifications
          </i>
              <span class="position-absolute top-5 start-100 translate-middle badge rounded-pill bg-danger border border-white small py-1 px-2">
                <span class="small">11</span>
                <span class="visually-hidden">unread notifications</span>
              </span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end p-2 me-sm-n4" aria-labelledby="dropdownMenuButton">
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="d-flex align-items-center py-1">
                    <span class="material-icons">email</span>
                    <div class="ms-2">
                      <h6 class="text-sm font-weight-normal my-auto">
                        Check new messages
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="d-flex align-items-center py-1">
                    <span class="material-icons">podcasts</span>
                    <div class="ms-2">
                      <h6 class="text-sm font-weight-normal my-auto">
                        Manage podcast session
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="d-flex align-items-center py-1">
                    <span class="material-icons">shopping_cart</span>
                    <div class="ms-2">
                      <h6 class="text-sm font-weight-normal my-auto">
                        Payment successfully completed
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  
<!-- 
<nav #navbar class="navbar navbar-default">
    <div class="container-fluid">
        <div class="navbar-minimize">
            <button id="minimizeSidebar" class="btn btn-danger btn-fill btn-round btn-icon">
                <i class="fa fa-ellipsis-v visible-on-sidebar-regular"></i>
                <i class="fa fa-navicon visible-on-sidebar-mini"></i>
            </button>
        </div>
        <div class="navbar-header">
            <button type="button" class="navbar-toggle" data-toggle="collapse" (click)="sidebarToggle()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
        </div>
        <div class="collapse navbar-collapse">
            <div class="" *ngIf="isMobileMenu()">
                <form class="navbar-form navbar-left navbar-search-form" role="search">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            <input list="id-menu" name="id-menu" [(ngModel)]="selectedMenu" value="{{selectedMenuObj?.name}}" (ngModelChange)="onChange()" class="form-control" placeholder="Search.." autocomplete="off">
                            <datalist id="id-menu">
                              <option *ngFor="let menu of menus; let i = index" [value]="menu?.name">{{menu?.name}}</option>
                            </datalist>
                    </div>
                </form>

                <ul class="nav navbar-nav navbar-right">
                    <li>
                        <button type="button" class="btn btn-fill btn-danger" (click)="publish();" *ngIf="showPublish">Publish</button>
                    </li>
                    <li class="dropdown dropdown-with-icons">
                        <a href="javascript:void(0);" class="dropdown-toggle" (click)="toggleMenu();">
                            <i class="fa fa-user"></i> {{user?.name}}
                        </a>
                        <ul class="dropdown-menu dropdown-with-icons" *ngIf="showMenu">
                            <li>
                                <a [routerLink]="['settings/password']">
                                    <i class="pe-7s-door-lock"></i> Password
                                </a>
                            </li>
                            <li>
                                <a href="https://support.oxq.io/client" target="_blank">
                                    <i class="pe-7s-help1"></i> Help Center
                                </a>
                            </li>
                            <li>
                                <a href="https://support.oxq.io/client/faq" target="_blank">
                                    <i class="pe-7s-info"></i> FAQs
                                </a>
                            </li>
                            <li class="divider"></li>
                            <li>
                                <a href="javascript:void(0);" (click)="logout();" class="text-danger">
                                    <i class="pe-7s-close-circle"></i>
                                    Log out
                                </a>
                            </li>
                        </ul>
                    </li>

                </ul>
            </div>
        </div>
    </div>
</nav> -->
