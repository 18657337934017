<footer class="footer py-4  ">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-lg-between">
        <div class="col-lg-6 mb-lg-0 mb-4">
            <div class="copyright text-center text-sm text-muted text-lg-start">
            &copy; {{cYear | date: 'yyyy'}} <a href="https://oxq.io" target="_blank">OXQ.IO</a>. All rights reserved.
            </div>
        </div>
        </div>
    </div>
</footer>

<!-- 
<footer class="footer">
    <div class="container-fluid">
        <p class="copyright pull-right">
            &copy; {{cYear | date: 'yyyy'}} <a href="https://oxq.io" target="_blank">OXQ.IO</a>. All rights reserved.
        </p>
    </div>
</footer> -->
