
<div class="sidenav-header">
  <i class="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
  <a class="navbar-brand m-0 text-center" [routerLink]="['','dashboard']">
    <img src="/assets/img/logo-w.png" class="logo-img" />
  </a>
</div>
<hr class="horizontal light mt-0 mb-2">
<div class="collapse navbar-collapse  w-auto h-auto" id="sidenav-collapse-main">
  <ul class="navbar-nav">
    <li class="nav-item" routerLinkActive="active">
      <a [routerLink]="['/dashboard']" class="nav-link text-white">
        <i class="material-icons-round opacity-10">dashboard</i>
        <span class="nav-link-text ms-2 ps-1">Dashboards</span>
      </a>
    </li>
    <li class="nav-item mt-3">
      <h6 class="ps-4  ms-2 text-uppercase text-xs font-weight-bolder text-white">CART</h6>
    </li>
    <li class="nav-item">
      <a data-bs-toggle="collapse" href="#productsSec" class="nav-link text-white " aria-controls="productsSec" role="button" aria-expanded="false">
        <i class="material-icons-round {% if page.brand == 'RTL' %}ms-2{% else %} me-2{% endif %}">local_mall</i>
        <span class="nav-link-text ms-2 ps-1">Products</span>
      </a>
      <div class="collapse " id="productsSec">
        <ul class="nav ">
          <li class="nav-item ">
            <a class="nav-link text-white " [routerLink]="['/cart', 'products', 'list']">
              <span class="sidenav-mini-icon"> LP </span>
              <span class="sidenav-normal  ms-2  ps-1"> List Products </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white " [routerLink]="['/cart', 'product', 'new']">
              <span class="sidenav-mini-icon"> NP </span>
              <span class="sidenav-normal  ms-2  ps-1"> New Product </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white " [routerLink]="['/cart', 'products', 'import']">
              <span class="sidenav-mini-icon"> IP </span>
              <span class="sidenav-normal  ms-2  ps-1"> Import Products </span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item">
      <a data-bs-toggle="collapse" href="#categoriesSec" class="nav-link text-white " aria-controls="categoriesSec" role="button" aria-expanded="false">
        <i class="material-icons-round {% if page.brand == 'RTL' %}ms-2{% else %} me-2{% endif %}">category</i>
        <span class="nav-link-text ms-2 ps-1">Categories</span>
      </a>
      <div class="collapse" id="categoriesSec">
        <ul class="nav ">
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/cart/categories', 'list']">
              <span class="sidenav-mini-icon"> LC </span>
              <span class="sidenav-normal  ms-2  ps-1"> List Categories </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white " [routerLink]="['/cart/category', 'new']">
              <span class="sidenav-mini-icon"> NC </span>
              <span class="sidenav-normal  ms-2  ps-1"> New Category </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white " [routerLink]="['/cart/categories', 'import']">
              <span class="sidenav-mini-icon"> IC </span>
              <span class="sidenav-normal  ms-2  ps-1"> Import Categories </span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item">
      <a data-bs-toggle="collapse" href="#vendorsSec" class="nav-link text-white " aria-controls="vendorsSec" role="button" aria-expanded="false">
        <i class="material-icons-round {% if page.brand == 'RTL' %}ms-2{% else %} me-2{% endif %}">inventory</i>
        <span class="nav-link-text ms-2 ps-1">Vendors</span>
      </a>
      <div class="collapse " id="vendorsSec">
        <ul class="nav ">
          <li class="nav-item ">
            <a class="nav-link text-white "  [routerLink]="['/cart/vendors', 'list']">
              <span class="sidenav-mini-icon"> LV </span>
              <span class="sidenav-normal  ms-2  ps-1"> List Vendors </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white "  [routerLink]="['/cart/vendor', 'new']">
              <span class="sidenav-mini-icon"> NV </span>
              <span class="sidenav-normal  ms-2  ps-1"> New Vendor </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white"  [routerLink]="['/cart/vendors', 'import']">
              <span class="sidenav-mini-icon"> IV </span>
              <span class="sidenav-normal  ms-2  ps-1"> Import Vendors </span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item">
      <a data-bs-toggle="collapse" href="#ordersSec" class="nav-link text-white " aria-controls="ordersSec" role="button" aria-expanded="false">
        <i class="material-icons-round {% if page.brand == 'RTL' %}ms-2{% else %} me-2{% endif %}">shopping_cart</i>
        <span class="nav-link-text ms-2 ps-1">Orders</span>
      </a>
      <div class="collapse " id="ordersSec">
        <ul class="nav ">
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/orders', 'new']">
              <span class="sidenav-mini-icon"> NO </span>
              <span class="sidenav-normal  ms-2  ps-1"> New Orders </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/orders', 'processing']">
              <span class="sidenav-mini-icon"> PO </span>
              <span class="sidenav-normal  ms-2  ps-1"> Processing Orders </span>
            </a>
          </li>              
          <li class="nav-item">
            <a class="nav-link text-white" [routerLink]="['/orders', 'delivered']">
              <span class="sidenav-mini-icon"> DO </span>
              <span class="sidenav-normal  ms-2  ps-1"> Delivered Orders </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" [routerLink]="['/orders', 'cancelled']">
              <span class="sidenav-mini-icon"> CO </span>
              <span class="sidenav-normal  ms-2  ps-1"> Cancelled Orders </span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    
    <li class="nav-item">
      <hr class="horizontal light" />
      <h6 class="ps-4  ms-2 text-uppercase text-xs font-weight-bolder text-white">FEEDBACK</h6>
    </li>

    <li class="nav-item">
      <a data-bs-toggle="collapse" href="#reviewsSec" class="nav-link text-white " aria-controls="reviewsSec" role="button" aria-expanded="false">
        <i class="material-icons-round {% if page.brand == 'RTL' %}ms-2{% else %} me-2{% endif %}">reviews</i>
        <span class="nav-link-text ms-2 ps-1">Reviews & Rating</span>
      </a>
      <div class="collapse " id="reviewsSec">
        <ul class="nav ">
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/rnr', 'new']">
              <span class="sidenav-mini-icon"> NR </span>
              <span class="sidenav-normal  ms-2  ps-1"> 7 Days RnR </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/rnr', 'old']">
              <span class="sidenav-mini-icon"> OR </span>
              <span class="sidenav-normal  ms-2  ps-1"> Old RnR</span>
            </a>
          </li>              
          <li class="nav-item">
            <a class="nav-link text-white" [routerLink]="['/rnr', 'disabled']">
              <span class="sidenav-mini-icon"> DR </span>
              <span class="sidenav-normal  ms-2  ps-1"> Disabled RnR </span>
            </a>
          </li>
        </ul>
      </div>
    </li>

    <li class="nav-item">
      <a data-bs-toggle="collapse" href="#testimonialsSec" class="nav-link text-white " aria-controls="testimonialsSec" role="button" aria-expanded="false">
        <i class="material-icons-round {% if page.brand == 'RTL' %}ms-2{% else %} me-2{% endif %}">rate_review</i>
        <span class="nav-link-text ms-2 ps-1">Testimonials</span>
      </a>
      <div class="collapse" id="testimonialsSec">
        <ul class="nav ">
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/testimonials', 'new']">
              <span class="sidenav-mini-icon"> NT </span>
              <span class="sidenav-normal  ms-2  ps-1"> New Testimonials </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/testimonials', 'list']">
              <span class="sidenav-mini-icon"> LT </span>
              <span class="sidenav-normal  ms-2  ps-1"> List Testimonials</span>
            </a>
          </li>
        </ul>
      </div>
    </li>

    <li class="nav-item">
      <hr class="horizontal light" />
      <h6 class="ps-4  ms-2 text-uppercase text-xs font-weight-bolder text-white">MANAGE</h6>
    </li>
    <li class="nav-item">
      <a data-bs-toggle="collapse" href="#themeSec" class="nav-link text-white " aria-controls="themeSec" role="button" aria-expanded="false">
        <i class="material-icons-round {% if page.brand == 'RTL' %}ms-2{% else %} me-2{% endif %}">brush</i>
        <span class="nav-link-text ms-2 ps-1">Themes</span>
      </a>
      <div class="collapse " id="themeSec">
        <ul class="nav ">
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/themes', 'list']">
              <span class="sidenav-mini-icon"> LT </span>
              <span class="sidenav-normal  ms-2  ps-1"> List Themes </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/themes', 'my']">
              <span class="sidenav-mini-icon"> MT </span>
              <span class="sidenav-normal  ms-2  ps-1"> My Theme </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/themes', 'create']">
              <span class="sidenav-mini-icon"> CT </span>
              <span class="sidenav-normal  ms-2  ps-1"> Create Theme </span>
            </a>
          </li>              
        </ul>
      </div>
    </li>

    <li class="nav-item">
      <a data-bs-toggle="collapse" href="#customizeSec" class="nav-link text-white " aria-controls="customizeSec" role="button" aria-expanded="false">
        <i class="material-icons-round {% if page.brand == 'RTL' %}ms-2{% else %} me-2{% endif %}">tune</i>
        <span class="nav-link-text ms-2 ps-1">Customize</span>
      </a>
      <div class="collapse " id="customizeSec">
        <ul class="nav ">
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/customize', 'logo']">
              <span class="sidenav-mini-icon"> CL </span>
              <span class="sidenav-normal ms-2 ps-1"> Logo </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/customize', 'slider']">
              <span class="sidenav-mini-icon"> CS </span>
              <span class="sidenav-normal ms-2  ps-1"> Slider </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white"  [routerLink]="['/customize', 'header']">
              <span class="sidenav-mini-icon"> CH </span>
              <span class="sidenav-normal  ms-2  ps-1"> Header </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/customize', 'icon']">
              <span class="sidenav-mini-icon"> CI </span>
              <span class="sidenav-normal  ms-2  ps-1"> Icon </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white"  [routerLink]="['/customize', 'code']">
              <span class="sidenav-mini-icon"> CC </span>
              <span class="sidenav-normal  ms-2  ps-1"> Code </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/customize', 'footer']">
              <span class="sidenav-mini-icon"> CF </span>
              <span class="sidenav-normal  ms-2  ps-1"> Footer </span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item">
      <a data-bs-toggle="collapse" href="#settingsSec" class="nav-link text-white " aria-controls="settingsSec" role="button" aria-expanded="false">
        <i class="material-icons-round {% if page.brand == 'RTL' %}ms-2{% else %} me-2{% endif %}">settings</i>
        <span class="nav-link-text ms-2 ps-1">Settings</span>
      </a>
      <div class="collapse " id="settingsSec">
        <ul class="nav ">
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/settings', 'general']">
              <span class="sidenav-mini-icon"> SG </span>
              <span class="sidenav-normal ms-2 ps-1"> General </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/settings', 'domain']">
              <span class="sidenav-mini-icon"> SD </span>
              <span class="sidenav-normal ms-2  ps-1"> Domain </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white"  [routerLink]="['/settings', 'cart']">
              <span class="sidenav-mini-icon"> SC </span>
              <span class="sidenav-normal  ms-2  ps-1"> Cart </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/settings', 'payment']">
              <span class="sidenav-mini-icon"> SP </span>
              <span class="sidenav-normal  ms-2  ps-1"> Payment </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white"  [routerLink]="['/settings', 'countries']">
              <span class="sidenav-mini-icon"> SC </span>
              <span class="sidenav-normal  ms-2  ps-1"> Countries </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/settings', 'email']">
              <span class="sidenav-mini-icon"> SE </span>
              <span class="sidenav-normal  ms-2  ps-1"> Email </span>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" [routerLink]="['/settings', 'social']">
              <span class="sidenav-mini-icon"> SS </span>
              <span class="sidenav-normal  ms-2  ps-1"> Social </span>
            </a>
          </li>
        </ul>
      </div>
    </li>

    <li class="nav-item">
      <hr class="horizontal light" />
      <h6 class="ps-4  ms-2 text-uppercase text-xs font-weight-bolder text-white">CONNECT</h6>
    </li>
    <li class="nav-item">
      <a [routerLink]="['/connect', 'apps']" class="nav-link text-white " role="button" aria-controls="connectApps">
        <i class="material-icons-round {% if page.brand == 'RTL' %}ms-2{% else %} me-2{% endif %}">hub</i>
        <span class="nav-link-text ms-2 ps-1">3rd Party Apps</span>
      </a>
      <a [routerLink]="['/connect', 'partners']" class="nav-link text-white mb-2" role="button" aria-controls="connectPartners">
        <i class="material-icons-round {% if page.brand == 'RTL' %}ms-2{% else %} me-2{% endif %}">diversity_3</i>
        <span class="nav-link-text ms-2 ps-1">Partners</span>
      </a>

    </li>
  </ul>
</div>

<!-- 
<div class="logo">
<a [routerLink]="['','dashboard']" class="simple-text logo-mini">
      <img src="/assets/img/logo-w.png" class="logo-img" />
</a>
</div>

<div class="sidebar-wrapper">

  <ul class="nav">

      <li routerLinkActive="active">
          <a [routerLink]="['/dashboard']">
              <i class="pe-7s-helm"></i>
              <p>Dashboard</p>
          </a>
      </li>

      <li routerLinkActive="active">
          <a data-toggle="collapse" href="javascript:void(0);" (click)="setActiveMenu('products');">
              <i class="pe-7s-gift"></i>
              <p>Products<b class="caret"></b></p>
          </a>


          <div id="Products" [ngClass]="{'collapse': activeMenu!='products' }">
              <ul class="nav">
                  <li routerLinkActive="active">
                      <a [routerLink]="['/products', 'all']">
                          <span class="sidebar-mini">PR</span>
                          <span class="sidebar-normal">Products</span>
                      </a>
                  </li>

                  <li routerLinkActive="active">
                      <a [routerLink]="['/products', 'categories']">
                          <span class="sidebar-mini">PC</span>
                          <span class="sidebar-normal">Categories</span>
                      </a>
                  </li>

                  <li routerLinkActive="active">
                      <a [routerLink]="['/products', 'brands']">
                          <span class="sidebar-mini">PB</span>
                          <span class="sidebar-normal">Brands</span>
                      </a>
                  </li>
              </ul>
          </div>
      </li>

      <li routerLinkActive="active">
          <a data-toggle="collapse" href="javascript:void(0);" (click)="setActiveMenu('orders');">
              <i class="pe-7s-cart"></i>
              <p>Orders<b class="caret"></b></p>
          </a>

          <div id="Orders" [ngClass]="{'collapse': activeMenu!='orders' }">
              <ul class="nav">
                  <li routerLinkActive="active">
                      <a [routerLink]="['/orders', 'new']">
                          <span class="sidebar-mini">ON</span>
                          <span class="sidebar-normal">New</span>
                      </a>
                  </li>

                  <li routerLinkActive="active">
                      <a [routerLink]="['/orders', 'processing']">
                          <span class="sidebar-mini">OP</span>
                          <span class="sidebar-normal">Processing</span>
                      </a>
                  </li>

                  <li routerLinkActive="active">
                      <a [routerLink]="['/orders', 'shipping']">
                          <span class="sidebar-mini">OS</span>
                          <span class="sidebar-normal">Shipping</span>
                      </a>
                  </li>

                  <li routerLinkActive="active">
                      <a [routerLink]="['/orders', 'delivered']">
                          <span class="sidebar-mini">OD</span>
                          <span class="sidebar-normal">Delivered</span>
                      </a>
                  </li>

                  <li routerLinkActive="active">
                      <a [routerLink]="['/orders', 'cancelled']">
                          <span class="sidebar-mini">OC</span>
                          <span class="sidebar-normal">Cancelled</span>
                      </a>
                  </li>
              </ul>
          </div>
      </li>


      <li routerLinkActive="active">
          <a data-toggle="collapse" href="javascript:void(0);" (click)="setActiveMenu('feedback');">
              <i class="pe-7s-note2"></i>
              <p>Feedback<b class="caret"></b></p>
          </a>

          <div id="Orders" [ngClass]="{'collapse': activeMenu!='feedback' }">
              <ul class="nav">
                  <li routerLinkActive="active">
                      <a [routerLink]="['/feedback', 'reviews']">
                          <span class="sidebar-mini">RR</span>
                          <span class="sidebar-normal">Rating n Reviews</span>
                      </a>
                  </li>

                  <li routerLinkActive="active">
                      <a [routerLink]="['/feedback', 'testimonials']">
                          <span class="sidebar-mini">TS</span>
                          <span class="sidebar-normal">Testimonials</span>
                      </a>
                  </li>
              </ul>
          </div>
      </li>

      <li routerLinkActive="active">
          <a data-toggle="collapse" href="javascript:void(0);" (click)="setActiveMenu('themes');">
              <i class="pe-7s-display1"></i>
              <p>Themes<b class="caret"></b></p>
          </a>

          <div id="Themes" [ngClass]="{'collapse': activeMenu!='themes' }">
              <ul class="nav">
                  <li routerLinkActive="active">
                      <a [routerLink]="['/themes', 'all']">
                          <span class="sidebar-mini">TH</span>
                          <span class="sidebar-normal">Themes</span>
                      </a>
                  </li>

                  <li routerLinkActive="active" *ngIf="themeType=='platform'">
                      <a [routerLink]="['/themes', 'sections']">
                          <span class="sidebar-mini">TC</span>
                          <span class="sidebar-normal">Theme Sections</span>
                      </a>
                  </li>

                  <li routerLinkActive="active" *ngIf="themeType=='platform'">
                      <a [routerLink]="['/themes', 'settings']">
                          <span class="sidebar-mini">TS</span>
                          <span class="sidebar-normal">Theme Settings</span>
                      </a>
                  </li>

                  <li routerLinkActive="active" *ngIf="themeType=='platform'">
                      <a [routerLink]="['/themes', 'custom']">
                          <span class="sidebar-mini">CS</span>
                          <span class="sidebar-normal">Custom Sections</span>
                      </a>
                  </li>
              </ul>
          </div>
      </li>

      <li routerLinkActive="active">
          <a data-toggle="collapse" href="javascript:void(0);" (click)="setActiveMenu('customize');">
              <i class="pe-7s-edit"></i>
              <p>Customize<b class="caret"></b></p>
          </a>

          <div id="Customize" [ngClass]="{'collapse': activeMenu!='customize' }">
              <ul class="nav">
                  <li routerLinkActive="active">
                      <a [routerLink]="['/customize', 'logo']">
                          <span class="sidebar-mini">LO</span>
                          <span class="sidebar-normal">Logo</span>
                      </a>
                  </li>

                  <li routerLinkActive="active">
                      <a [routerLink]="['/customize', 'slider']">
                          <span class="sidebar-mini">SL</span>
                          <span class="sidebar-normal">Slider</span>
                      </a>
                  </li>

                  <li routerLinkActive="active">
                      <a [routerLink]="['/customize', 'header']">
                          <span class="sidebar-mini">HE</span>
                          <span class="sidebar-normal">Header</span>
                      </a>
                  </li>

                  <li routerLinkActive="active">
                      <a [routerLink]="['/customize', 'menu']">
                          <span class="sidebar-mini">MU</span>
                          <span class="sidebar-normal">Menu</span>
                      </a>
                  </li>

                  <li routerLinkActive="active">
                      <a [routerLink]="['/customize', 'icons']">
                          <span class="sidebar-mini">IC</span>
                          <span class="sidebar-normal">Icons</span>
                      </a>
                  </li>

                  <li routerLinkActive="active">
                      <a [routerLink]="['/customize', 'footer']">
                          <span class="sidebar-mini">FO</span>
                          <span class="sidebar-normal">Footer</span>
                      </a>
                  </li>

                  <li routerLinkActive="active">
                      <a [routerLink]="['/customize', 'code']">
                          <span class="sidebar-mini">CC</span>
                          <span class="sidebar-normal">Custom Code</span>
                      </a>
                  </li>
              </ul>
          </div>
      </li>            

      <li routerLinkActive="active">
          <a data-toggle="collapse" href="javascript:void(0);" (click)="setActiveMenu('settings');">
              <i class="pe-7s-config"></i>
              <p>Settings<b class="caret"></b></p>
          </a>

          <div id="Settings" [ngClass]="{'collapse': activeMenu!='settings' }">
              <ul class="nav">
                  <li routerLinkActive="active">
                      <a [routerLink]="['/settings', 'all']">
                          <span class="sidebar-mini">GE</span>
                          <span class="sidebar-normal">General</span>
                      </a>
                  </li>

                  <li routerLinkActive="active">
                      <a [routerLink]="['/settings', 'connect']">
                          <span class="sidebar-mini">CT</span>
                          <span class="sidebar-normal">Connect</span>
                      </a>
                  </li>

                  <li routerLinkActive="active" *ngIf="defaults?.dropshipping!=1">
                      <a [routerLink]="['/settings', 'cart']">
                          <span class="sidebar-mini">CA</span>
                          <span class="sidebar-normal">Cart</span>
                      </a>
                  </li>

                  <li routerLinkActive="active" *ngIf="defaults?.dropshipping!=1">
                      <a [routerLink]="['/settings', 'countries']">
                          <span class="sidebar-mini">CS</span>
                          <span class="sidebar-normal">Countries</span>
                      </a>
                  </li>                    

                  <li routerLinkActive="active" *ngIf="defaults?.dropshipping!=1">
                      <a [routerLink]="['/settings', 'payment']">
                          <span class="sidebar-mini">PY</span>
                          <span class="sidebar-normal">Payment</span>
                      </a>
                  </li>

                  <li routerLinkActive="active">
                      <a [routerLink]="['/settings', 'social']">
                          <span class="sidebar-mini">SM</span>
                          <span class="sidebar-normal">Social Media</span>
                      </a>
                  </li>

                  <li routerLinkActive="active" *ngIf="defaults?.dropshipping!=1">
                      <a [routerLink]="['/settings', 'email']">
                          <span class="sidebar-mini">@</span>
                          <span class="sidebar-normal">Email</span>
                      </a>
                  </li>
              </ul>
          </div>
      </li>

      <li routerLinkActive="active" *ngIf="defaults?.show_partners==1">
          <a data-toggle="collapse" href="javascript:void(0);" (click)="setActiveMenu('partners');">
              <i class="pe-7s-link"></i>
              <p>Partners<b class="caret"></b></p>
          </a>

          <div id="Connect" [ngClass]="{'collapse': activeMenu!='partners' }">
              <ul class="nav">
                  <li routerLinkActive="active">
                      <a [routerLink]="['/partners', 'all']">
                          <span class="sidebar-mini">SA</span>
                          <span class="sidebar-normal">Show All</span>
                      </a>
                  </li>

                  <li routerLinkActive="active" *ngIf="defaults?.dropshipping==1">
                      <a [routerLink]="['/partners', 'products', typeLink]">
                          <span class="sidebar-mini"> {{typeLink | slice:0:2}}</span>
                          <span class="sidebar-normal">{{typeLink}}</span>
                      </a>
                  </li>                    

                  <li routerLinkActive="active" *ngIf="defaults?.dropshipping==1">
                      <a [routerLink]="['/connect', 'disconnect']">
                          <span class="sidebar-mini"> DT</span>
                          <span class="sidebar-normal">Disconnect</span>
                      </a>
                  </li>

              </ul>
          </div>
      </li>
      
  </ul>

</div> -->