import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})

export class PartnersService extends SharedService {

  public productsSub = new BehaviorSubject<any>([]);

  public domain = 'https://bix.oxq.io/';
  // public domain = 'http://localhost:888/bix/';


  getProducts(tid){

      let products = this.getProductsFromCache(tid);

      if(products){
        this.productsSub.next(products);        
      }else{
        this.getProductsFromService(tid).subscribe();
      }
  }

  getProductsFromCache(tid):Array<any>{
    return this.cache.get('ds_products_'+tid);
  }

  getProductsFromService(tid):Observable<any>{
    let token = this.getToken();

    return Observable.create(observer => {
      this.http.get<any>(this.apiUrl + "partners/products?token="+token+"&tid="+tid, this.httpOptions).subscribe(res=>{
        this.cache.set('ds_products_'+tid,res);
        this.productsSub.next(res);
        observer.next(res);
        observer.complete();
      }, (error) => {
        this.handleError(error);
      });
    });
  }

  getProduct(id, type){

  }

  public loadService(id):Observable<any>{
    let token = this.getToken();
    return Observable.create(observer => {
      this.http.get<any>(this.apiUrl + "partners?token=" + token + '&id=' + id, this.httpOptions).subscribe(res=>{
        observer.next(res);
        observer.complete();
      }, (error) => {
        this.handleError(error);
      });
    });        
  }

  public get(){
    let ret = [
      {id: 2, name: 'eveline'},
      {id: 3, name: 'remz'},
      {id: 4, name: 'sremeh_sol'},
      {id: 1001, name: 'printify-us'},
      {id: 1002, name: 'printify-uk'}
    ];
    return ret;
  }

}
