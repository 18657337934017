import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loaderSub = new Subject<any>();

  show(val){
    this.loaderSub.next(val);
  }
  
}
