import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { PartnersService } from '../../services/partners.service';
import { DefaultsType } from '../../interfaces/defaults';
import { PartnersType } from '../../interfaces/partners';

declare var $: any;

@Component({
  selector: 'oxq-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {

  constructor(
    private _shared: SharedService,
    private _partners: PartnersService
  ) { }    

  isNotMobileMenu(){
    if($(window).width() > 991){
        return false;
    }
    return true;
  }

  public defaults: DefaultsType;
  public typeData: PartnersType;
  public typeArr: any[];
  public menuItems: any[];
  public activeMenu: string;
  public themeType: string;  
  public typeLink: string;

  ngOnInit() {

    this.typeArr = this._partners.get();

    this._shared.getRequest('site/defaults', 'defaults').subscribe(defaults=>{
      if(defaults?.theme_id=='x1' || defaults?.theme_id=='x2' || defaults?.theme_id=='x3' || defaults?.theme_id=='x4' || defaults?.theme_id=='x5'){
          this.themeType = 'platform';
      }

      this.defaults = defaults;

      if(this.defaults?.ds_id){
        this.typeArr.forEach(t=>{
          if(t.id==this.defaults?.ds_id){
            this.typeLink = t.name;
          }
        });
      }
    });

  }

  setActiveMenu(m){
    if(this.activeMenu==m){
      this.activeMenu = null;
    }else{
      this.activeMenu = m;
    }
  }

}
