import { Component,   Renderer2, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewInit {
  
  constructor(protected _renderer: Renderer2) {}  

  ngAfterViewInit() {
    let loader = this._renderer.selectRootElement(".full-screen-loader");
    this._renderer.setStyle(loader, "display", "none");
  }
}
