import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private storage = window.localStorage;

  public set(key: any, value: any): void {
    this.storage.setItem(key, JSON.stringify(value));
  }

  public get(key?: any): any {
    let data = this.storage[key] || undefined;

    if (data)
        return JSON.parse(data);
    else
        return null;
  }

  public del(key?: any){
    this.storage.removeItem(key);
  }

}
