import { Injectable, Inject, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { APP_CONFIG, AppConfig } from '../../app-config.module';
import { Router } from '@angular/router';
import { CacheService } from './cache.service';
import { NotificationService } from './notification.service';
import { LoaderService } from '../components/loader/loader.service';
import { UserType } from '../interfaces/user';

import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})

export class SharedService {

  private _storage = window.localStorage;
  
  public apiUrl:string;
  public cdnUrl:string;
  public stripe:string;
  public ipinfo:string;
  
  public getSub = new Subject();

  public httpOptions    = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  public httpTxtOptions = { headers: new HttpHeaders({ 'Content-Type': 'text/html; charset=utf-8', responseType: 'text' }) };

  constructor(
    protected http:HttpClient,
    protected router:Router,
    protected cache:CacheService,
    protected notify: NotificationService,
    protected loader: LoaderService,
    @Inject(APP_CONFIG) protected config: AppConfig  	
  ) {
    this.apiUrl = this.config.api;
    this.cdnUrl = this.config.cdn;  	
    this.stripe = this.config.stripe;
    this.ipinfo = this.config.ipinfo;
  }

  public getToken():string{
    let token:string = this.cache.get('token');   
    if (!_.isEmpty(token)){
        return token;
    }else{
      return null;
    }  	
  }

  public getTokenHeader(token){
    let headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }) };
    return headers;
  }

  public parseResponse(res): Array<any> {
    let r = JSON.parse(res);
    return r;
  }  

  public handleError(e){
    if(e.status==401){
      this._nullCache();
      this.router.navigate(['/users/login']);
    }else if(e.error){
      let errors = e?.error;
      if(errors.message){
        this.notify.show('danger', errors.message);
      }else{
        _.each(errors, er=>{
          this.notify.show('danger', er[0]?er[0]:'Something went wrong, please contact support.');
        });        
      }
      this.loader.show(false);
    }else{
      this.notify.show('danger','Something went wrong, please contact support.');
      this.loader.show(false);
    }
  }

  public orderStatus(st){
    if(st==0){
      return 'New';
    }else if(st==1){
      return 'Processing';
    }else if(st==3){
      return 'Shipping';
    }
  }

  public decodeHtml(str) {
    if(str){
      str = str.replaceAll('&amp;', "&");
      str = str.replaceAll('&#039;', "'");
      str = str.replaceAll('&quot;', '"');
      str = str.replaceAll('&lt;', "<");
      str = str.replaceAll('&gt;', ">");
    }
    return str;
  }

  public parseHtml(html){
    html = '<div id="xIDoxq1">' + html + '</div>';
    const domParser = new DOMParser();
    const htmlElement = domParser.parseFromString(html, 'text/html');
    const divObj = htmlElement.getElementById('xIDoxq1').innerText;
    return divObj;
  }

  //Generate Unique ID
  public genId() {
    return Math.random().toString(36).substr(2, 9);
  }

  public getRequestFromService(url, type):Observable<any>{
    let token  = this.getToken();
    let header = this.getTokenHeader(token);
    return Observable.create(observer => {
      this.http.get<any>(this.apiUrl + url, header).subscribe(r=>{
        this.cache.set(type, r);
        observer.next(r);
        observer.complete();
      }, (error) => {
        this.handleError(error);
      });
    });
  }

  public getRequestFromCache(type){
    return Observable.create(observer => {
      let data = this.cache.get(type);    
      observer.next(data);
      observer.complete();
    });
    
  }

  public loadSingleObject(type, id){
    let obj = this.cache.get(type);
    if(_.isEmpty(obj)){
      this.notify.show('danger', 'Unable to load ' + obj + ', please try again. If this error continues please logout and login again.');
    }
    return obj.find(obj=>obj.id==id);
  }

  public getRequest(url, type):Observable<any> {
    return Observable.create(observer => {
      this.getRequestFromCache(type).subscribe(data=>{
        if(data){
          observer.next(data);
          observer.complete();
        }else{
          this.getRequestFromService(url, type).subscribe(res=>{
            observer.next(res);
            observer.complete();
          });              
        }
      });
    })
  }

  public patchRequest(url, type){
    let token  = this.getToken();
    let header = this.getTokenHeader(token);
    return Observable.create(observer => {
      this.http.patch<any>(this.apiUrl + url, '', header).subscribe(r=>{
        if(type!=null){
          this.cache.set(type,r);
        }
        observer.next(r);
        observer.complete();
      }, (error) => {
        this.handleError(error);
      });
    });
  }

  public postRequest(url, data, type=null){
    let token  = this.getToken();
    let header = this.getTokenHeader(token);
    console.log(header);
    return Observable.create(observer => {
      this.http.post<any>(this.apiUrl + url, data, header).subscribe(r=>{
        if(type){
          this.cache.set(type,r);
        }
        observer.next(r);
        observer.complete();
      }, (error) => {
        this.handleError(error);
      });
    });
  }

  public putRequest(url, data, type){
    let token = this.getToken();
    let header = this.getTokenHeader(token);
    return Observable.create(observer => {
      this.http.put<any>(this.apiUrl + url, data, header).subscribe(r=>{
        this.cache.set(type,r);
        observer.next(r);
        observer.complete();
      }, (error) => {
        this.handleError(error);
      });
    });
  }  

  public deleteRequest(url, type){
    let token  = this.getToken();
    let header = this.getTokenHeader(token);

    return Observable.create(observer => {
     this.http.delete<any>(this.apiUrl + url, header).subscribe(res=>{
        this.cache.del(type);
        this.cache.set(type, res);
        observer.next(res);
        observer.complete();
      }, (error) => {
        this.handleError(error);
      });
    });    
  }

  getImageUrl(link){
    let url;
    if(link){
      url = this.cdnUrl + link;
    }else{
      url = '/assets/img/placeholder.png';
    }
    return url;
  }

  //Private function to clear cache
  private _nullCache(){
    this.cache.set('user', {});
    this.cache.set('dashboard', {});
    this.cache.set('defaults', {});
    this.cache.set('brands', {});
    this.cache.set('products', {});
    this.cache.set('categories', {});
    this.cache.set('slider', {});
    this.cache.set('orders', {});
    this.cache.set('doneOrders', {});    
  }
  
  // private customFormSource = new BehaviorSubject<string>('');
  // customFormSub = this.customFormSource.asObservable();

  // setCustomFormData(val){
  //   console.log('FormData:',val);
  //   //  this.customFormSource.next(val);
  // }  
}
