import { Routes } from '@angular/router';

import { AdminComponent } from './layouts/admin/admin.component';
import { AuthComponent } from './layouts/auth/auth.component';

import { AuthGuard } from './shared/guards/auth.guard';
import { NonAuthGuard } from './shared/guards/non-auth.guard';

export const AppRoutes: Routes = [{
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },{
        path: '',
        component: AdminComponent,
        children: [{
            path: '',
            loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
            canActivate: [AuthGuard]
        },{
            path: 'themes',
            loadChildren: () => import('./themes/themes.module').then(m => m.ThemesModule),
            canActivate: [AuthGuard]
        },{
            path: 'cart',
            loadChildren: () => import('./cart/cart.module').then(m=>m.CartModule),
            canActivate: [AuthGuard]
        },{
            path: 'products',
            loadChildren: () => import('./products/products.module').then(m => m.ProductsModule),
            canActivate: [AuthGuard]
        },{
            path: 'orders',
            loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
            canActivate: [AuthGuard]
        },{
            path: 'customize',
            loadChildren: () => import('./customize/customize.module').then(m => m.CustomizeModule),
            canActivate: [AuthGuard]
        },{
            path: 'settings',
            loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
            canActivate: [AuthGuard]
        },{
            path: 'apps',
            loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule),
            canActivate: [AuthGuard]
        },{
            path: 'partners',
            loadChildren: () => import('./partners/partners.module').then(m => m.PartnersModule),
            canActivate: [AuthGuard]
        },{
            path: 'feedback',
            loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule),
            canActivate: [AuthGuard]            
        }]

      },{
      path: '',
      component: AuthComponent,
      children: [{
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        // canActivate: [NonAuthGuard]
      }, {
        path: 'setup',
        loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule),
        canActivate: [AuthGuard]
      }]
    }
];