import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'ox-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  constructor(private _loader:LoaderService) { }

  public showLoader = false;

  ngOnInit() {

	this._loader.loaderSub.subscribe(v=>{
  		this.showLoader = v;
  	});
  }

}
