import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

import { SharedService } from './shared.service';
import { DefaultsType } from '../interfaces/defaults';
import { UserType } from '../interfaces/user';

import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class UserService extends SharedService {

  public defaultSub = new BehaviorSubject({});
  public orderSub   = new BehaviorSubject({});

  login(user, pass): Observable<UserType>{
  	
    let userData = {
        email: user,
        password: pass
    }
	  return Observable.create(observer => {
  		this.http.post<any>(this.apiUrl + "login", userData, this.httpOptions).subscribe(r=>{
        console.log(r);
        this.cache.set('token',r?.access_token);
        this.cache.set('user',r?.user);
        observer.next(r);
        observer.complete();
  		}, (error) => {
        this.handleError(error);
        // observer.error(error.error);
    	});
  	});
  }

  finishWizard(){
    let token = this.getToken();
    return Observable.create(observer => {
      this.http.get(this.apiUrl + "user/finish_wizard?token="+token, this.httpOptions).subscribe((r:any)=>{
        observer.next();
        observer.complete();
      }, (error) => {
        this.handleError(error);
        // observer.error(error.error);
      });
    });        
  }

  logout(){
    let token = this.getToken();
    return Observable.create(observer => {
      this.http.get(this.apiUrl + "logout?token="+token, this.httpOptions).subscribe((r:any)=>{
        this.clearCache();
        this.router.navigate(['/users/login']);
        observer.next();
        observer.complete();
      }, (error) => {
        this.handleError(error);
        // observer.error(error.error);
      });
    });    
  }

  recover(email): Observable<UserType>{
    
    let userData = {
        email: email
    }

    return Observable.create(observer => {
      this.http.post<any>(this.apiUrl + "user/recover",userData,this.httpOptions).subscribe(r=>{
          observer.next(r);
          observer.complete();
          // this.router.navigate(['/users/login']);
      }, (error) => {
        this.handleError(error);
        // const _body = this.parseResponse(error);
        // observer.error(_body);
      });
    });
  }

  clearCache(){
    this.cache.del('brands');
    this.cache.del('dashboard');
    this.cache.del('defaults');
    this.cache.del('categories');
    this.cache.del('doneOrders');
    this.cache.del('menus');
    this.cache.del('orders');
    this.cache.del('products');
    this.cache.del('slider');
    this.cache.del('token');
    this.cache.del('user');
  }

  getUser(){
    let user = this.getUserFromCache();    
    if(!_.isEmpty(user)){
      return user;
    }else{
      this.router.navigate(['/users/login']);
    }
  }

  getUserFromCache():UserType{
    return this.cache.get('user');
  }

  register(userData){
    return Observable.create(observer => {
      this.http.post<any>(this.apiUrl + "register",userData,this.httpOptions).subscribe(r=>{
        observer.next(r);
        observer.complete();
        // this.router.navigate(['/users/register', { type:'verify'}]);
      }, (error) => {
        this.handleError(error);
        // observer.error(error.error);
      });
    });
  }

  verify(userData){
    return Observable.create(observer => {
      this.http.post<any>(this.apiUrl + "user/verify",userData,this.httpOptions).subscribe(r=>{
        observer.next(r);
        observer.complete();
        this.router.navigate(['/users/register', { type:'thanks'}]);
      }, (error) => {
        this.handleError(error);
        // observer.error(error.error);
      });
    });
  }

  emailDuplicateCheck(email){
    return Observable.create(observer => {
      // this.http.post<any>(this.apiUrl + "user/check-email", {'email': email} ,this.httpOptions).subscribe(r=>{
      this.http.get(this.apiUrl + "check-email/"+email ,this.httpOptions).subscribe(r=>{      
        observer.next(r);
        observer.complete();
      }, (error) => {
        observer.error(error);
        // console.log(error);
        // if(error?.status==406){
        //   this.notify.show('danger','Email already exist. Please login.');
        // }
        // this.handleError(error);
        // observer.error(error.error);
      });
    });
  }

  getPaymentPage(userData){
    let token = this.getToken();
    return Observable.create(observer => {
      this.http.post<any>(this.apiUrl + "user/get_payment_page?token="+token,userData,this.httpOptions).subscribe(r=>{
          observer.next(r);
          observer.complete();
      }, (error) => {
          // observer.error(error.error);
          this.handleError(error?.error?.msg);
        });
    });
  }
  
  getUserOrder(){
    let order = this.getUserOrderFromCache();
    if(_.isEmpty(order)){
      this.getUserOrderFromService().subscribe(()=>{
        order = this.getUserOrderFromCache();
        this.orderSub.next(order);
      });
    }else{
      this.orderSub.next(order);
    }
  }

  getUserOrderFromCache():UserType{
    return this.cache.get('user-order');
  }

  getUserOrderFromService(){
    let token = this.getToken();
    return Observable.create(observer => {
      this.http.get(this.apiUrl + "user/get_order_info?token="+token, this.httpOptions).subscribe((r:any)=>{
        this.cache.set('user-order', r?.data);
        observer.next(r);
        observer.complete();
      }, (error) => {
          // observer.error(error.error);
          this.handleError(error?.error?.msg);
        });
    });
  }

  // payStripe(userData){
  //   let token = this.getToken();
  //   return Observable.create(observer => {
  //     this.http.post<any>(this.apiUrl + "user/pay_stripe?token="+token, userData, this.httpOptions).subscribe((r:any)=>{
  //       observer.next(r);
  //       observer.complete();
  //     }, (error) => {
  //       this.handleError(error?.error?.msg);
  //     });
  //   });
  // }

  updateDomain(userData){
    let token = this.getToken();
    return Observable.create(observer => {
      this.http.post<any>(this.apiUrl + "user/update_domain?token="+token, userData, this.httpOptions).subscribe((r:any)=>{
        let user = this.cache.get('user');
        user.domain = r?.data;
        this.cache.set('user', user);
        observer.next(r);
        observer.complete();
      }, (error) => {
        this.handleError(error?.error?.msg);
      });
    });
  }

  updatePass(userData){
    let token = this.getToken();
    return Observable.create(observer => {
      this.http.post<any>(this.apiUrl + "user/update_password?token="+token, userData, this.httpOptions).subscribe((r:any)=>{
        observer.next(r);
        observer.complete();
      }, (error) => {
        this.handleError(error);
      });
    });    
  }

  reset(data){
    return Observable.create(observer => {
      this.http.post<any>(this.apiUrl + "user/reset_password", data, this.httpOptions).subscribe((r:any)=>{
        observer.next(r);
        observer.complete();
      }, (error) => {
        this.handleError(error);
      });
    });       
  }

  verifyEmail(data){
    return Observable.create(observer => {
      this.http.post<any>(this.apiUrl + "verify-email", data, this.httpOptions).subscribe((r:any)=>{
        observer.next(r);
        observer.complete();
      }, (error) => {
        this.handleError(error);
      });
    });       
  }

  getPlans():Observable<DefaultsType>{
    let token = this.getToken();

    return Observable.create(observer => {
      this.http.get<any>(this.apiUrl + "plans", this.httpOptions).subscribe(r=>{
        observer.next(r);
        observer.complete();
      }, (error) => {
        this.handleError(error);
      });
    });
  }

  loadCountries() {
    return this.http.get('assets/countries.json');
  }  

  // checkDomain(data){
  //   let token = this.getToken();

  //   return Observable.create(observer => {
  //     this.http.post<any>(this.apiUrl + "user/check_domain", data, this.httpOptions).subscribe(res=>{
  //       observer.next(res);
  //       observer.complete();
  //     }, (error) => {
  //       this.handleError(error);
  //       // observer.error(error?.error);
  //     });
  //   });
  // }

  getUserCountry(){
    return this.http.get("https://ipinfo.io/country?token="+this.ipinfo, { responseType: 'text' });
  }
}
